<template>
  <ArchiveWrapper />
</template>

<script>
import ArchiveWrapper from '@/components/archive/ArchiveWrapper'

export default {
  name: 'Archive',
  components: {
    ArchiveWrapper
  }
}
</script>
