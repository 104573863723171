<template>
  <v-container
    fluid
  >
    <basic-note-hint
      class="mb-3 mt-4"
    >
      {{ $i18n.t('$l.hint.caseArchiveHint') }}
    </basic-note-hint>

    <basic-card
      title="$l.archive.caseListClosed"
      class="mt-12"
    >
      <app-api-call
        class="ma-0 pa-0 mb-3"
        :call-result="callResult"
      />
      <ArchivedCases
        v-if="callResult.finished && callResult.authorized"
      />
      <basic-service-required
        v-else
        title="$l.investigation.archivePackageRequired"
      />
    </basic-card>
  </v-container>
</template>

<script>
import ArchivedCases from './ArchivedCases'

export default {
  name: 'ArchiveWrapper',
  components: {
    ArchivedCases
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.callResult.error = null
      this.callResult.info = this.$i18n.t('$l.info.checkingRights')
      this.$xapi.post('endpointsUserProfile/hasRequiredRights', ['RIGHT_ArchiveWrapper'])
        .then(r => {
          this.callResult.authorized = true
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
          this.callResult.info = null
        })
    }
  }
}
</script>
