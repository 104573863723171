<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    :loading="loading"
    class="okaml_cursor_pointer"
    :mobile-breakpoint="0"
    :server-items-length="serverItemsLength"
    :footer-props="{
      itemsPerPageOptions:[10, 15, 20]
    }"
    @click:row="redirectToCaseResolution"
    @update:options="updateOptions"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        color="white"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$i18n.t('$l.app.search')"
          single-line
          hide-details
        />
      </v-toolbar>
    </template>
    <template v-slot:[`item.subjects`]="{ item }">
      <div
        v-for="(subj, idx) in item.subjects"
        :key="idx"
        class="text-caption"
      >
        {{ subj }}
      </div>
    </template>
    <template v-slot:[`item.type`]="{ item }">
      {{ $i18n.t(`$l.case.${item.type}`) }}
    </template>
    <template v-slot:[`item.result.finishDate`]="{ item }">
      <span style="white-space: nowrap">{{ $utils.formatDateWithoutTime(item.result.finishDate) }}</span>
    </template>
    <template v-slot:[`item.result.userDefinedRiskLevel`]="{ item }">
      {{ $i18n.t('$l.case.level' + item.result.userDefinedRiskLevel) }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'ArchivedCases',
  data () {
    return {
      loading: false,
      search: '',
      caseDetailDialog: false,
      items: [],
      serverItemsLength: 0,
      itemsPerPage: 15,
      sortBy: null,
      sortDesc: null,
      pagination: {}
    }
  },
  computed: {

    headers () {
      return [
        { text: this.$t('$l.case.name'), value: 'name' },
        { text: this.$t('$l.case.participants'), value: 'subjects', sortable: false },
        { text: this.$t('$l.case.type'), value: 'type' },
        { text: this.$t('$l.case.risk'), value: 'result.userDefinedRiskLevel', align: 'center' },
        { text: this.$t('$l.case.dateFinished'), value: 'result.finishDate' }
      ]
    },
    params (nv) {
      return {
        ...this.pagination,
        query: this.search
      }
    }
  },
  watch: {
    params: {
      handler () {
        this.fetchEntriesDebounced()
      },
      deep: true
    }
  },
  methods: {
    fetchEntriesDebounced () {
    // cancel pending call
      clearTimeout(this._timerId)
      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.loadData(this.itemsPerPage, 0)
      }, 500)
    },
    redirectToCaseResolution (item) {
      if (item.type === 'TransactionCase') {
        this.$router.push({ name: 'ResolveCase', params: { caseId: item.caseId } })
      } else {
        this.$router.push({ name: 'ResolveCasePerson', params: { caseId: item.caseId } })
      }
    },
    loadData (limit, offset) {
      this.loading = true
      var url = null
      url = `case/caseStatus/CLOSED/${limit}/${offset}/${this.sortBy[0]}/${this.sortDesc[0]}`
      if (this.search) {
        url += '/' + this.search
      }
      this.$xapi.get(url)
        .then(r => {
          this.serverItemsLength = r.data.total
          this.items = r.data.items
        }).catch(e => {
          this.apiError = e.response.localizedMessage
          this.items = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateOptions (event) {
      var offset = ((event.page - 1) * event.itemsPerPage)
      this.sortBy = event.sortBy
      this.sortDesc = event.sortDesc
      this.loadData(event.itemsPerPage, offset)
    }
  }
}

</script>
