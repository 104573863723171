var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"okaml_cursor_pointer",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"mobile-breakpoint":0,"server-items-length":_vm.serverItemsLength,"footer-props":{
    itemsPerPageOptions:[10, 15, 20]
  }},on:{"click:row":_vm.redirectToCaseResolution,"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$i18n.t('$l.app.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.subjects",fn:function(ref){
  var item = ref.item;
return _vm._l((item.subjects),function(subj,idx){return _c('div',{key:idx,staticClass:"text-caption"},[_vm._v(" "+_vm._s(subj)+" ")])})}},{key:"item.type",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$i18n.t(("$l.case." + (item.type))))+" ")]}},{key:"item.result.finishDate",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$utils.formatDateWithoutTime(item.result.finishDate)))])]}},{key:"item.result.userDefinedRiskLevel",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$i18n.t('$l.case.level' + item.result.userDefinedRiskLevel))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }